import React from 'react'
import Modal from '../Modal'
import I18n from 'i18n/i18n'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { updateCurrentPopupID } from 'store/actions/common/currentPopupIDActionCreators'
import { isCustomerEditBooking } from 'utils/new_booking/common'
import { getTippingPaymentSetting, handleInvalidPaymentAction } from 'store/actions/common/bookingActionCreators'

import './InvalidPaymentModal.scss'

const InvalidPaymentPopup = () => {
  const booking = useSelector(state => state.booking)
  const dispatch = useDispatch()
  const { id } = useParams()
  const extraInfos = useSelector(state => state.extraInfos)

  const renderActions = () => {
    if (isCustomerEditBooking()) {
      return (
        <div className="action-button">
          <button type="button" className="back-edit-btn" onClick={() => window.location.href = `/bookings/${id}`}>
            {I18n.t('export.bookingInfo')}
          </button>
          <button type="button" className="main-btn" onClick={() => window.fcWidget.open()}>
            {I18n.t('webapp.action.contact_cs')}
          </button>
        </div>
      )
    }

    if(Boolean(booking.vendorTippingPaymentSetting)) {
      return (
        <div className="action-button">
          <button type="button" className="main-btn" onClick={() => {
            dispatch(getTippingPaymentSetting({
              id: booking?.bookingPaymentDetails?.payment_method_setting_uuid,
              countryCode: booking?.country_code
            }))
            dispatch(updateCurrentPopupID(''))
          }}>
            {I18n.t('batches.button.ok_option')}
          </button>
        </div>
      )
    }

    return (
      <div className="action-button">
        <button type="button" className="main-btn" onClick={() => dispatch(
          handleInvalidPaymentAction(
            extraInfos.country_code,
            booking?.bankTransfer?.id,
            () => dispatch(updateCurrentPopupID(''))
          )
        )}>
          {I18n.t('batches.button.ok_option')}
        </button>
      </div>
    )
  }

  const renderTitleModal = () => {
    const isTipping = Boolean(booking.vendorTippingPaymentSetting)
    
    if(isTipping) {
      return I18n.t('webapp.label.action_needed_title')
    }

    return I18n.t('webapp.label.payment_invalid_modal_title')
  }

  const renderContentModal = () => {
    const isTipping = Boolean(booking.vendorTippingPaymentSetting)

    if(isCustomerEditBooking()) {
      return I18n.t('webapp.label.payment_invalid_modal_content_ceb')
    } else if(isTipping) {
      return I18n.t('webapp.label.action_needed_content')
    } else {
      return I18n.t('webapp.label.payment_invalid_modal_content')
    }
  }

  return (
    <Modal className="invalid-payment-popup">
      <div className="container">
        <div className="header">
          {renderTitleModal()}
        </div>
        <div className="content">
          <div className="padding-style">
            <div className="text">
              {renderContentModal()}
            </div>
            {renderActions()}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default InvalidPaymentPopup
